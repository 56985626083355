import React, {Component} from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import { StaffConstants} from '../../../../constants/';
import { httpServices } from '../../../../services/httpServices'
import * as _R from 'ramda';
import { ErrorPage } from '../../../common/error';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
//import related components
import { AdjustmentDetails, FTODetails, TimeofftypePolicyDetails, TimeoffConfigOverrideDetails, StaffEventsConfigContainer } from '.';

const styles = theme => ({
    paper:{
        padding:'20px',
        paddingTop:0,
    }
});
// This class component is depends Data property
export class TimeoffDetails extends Component{
    // This is for future purpose
    constructor(props) {
        super(props);
        this.state = {
            year:new Date().getFullYear(),
            selectedData: {}
        };
        this.HandleYearFilterChange = this.HandleYearFilterChange.bind(this);
    }
    HandleYearFilterChange = (evt, value) => {
        const year = evt.target.value;
        this.setState({
            year: year,
        },function() {
            this.getTimeoffDetails();
        });
    };
    getTimeoffDetails = () => {
        const {StaffID, UserID} = this.props;
        const year = this.state.year;
        let apiEndpoint = StaffConstants['TIMEOFF'];
        return httpServices.post(apiEndpoint, { 'id': UserID, 'staffID': StaffID, 'module': 'TIMEOFF', 'year':year })
            .then(response => {
                if (response.data) {
                    this.setState({
                        selectedData: response.data
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });

    }

    render(){
        return(
            (this.props.TimeoffData.permission) 
            ?
            <TimeoffComponent
                TimeoffData={(_R.not(_R.isEmpty(this.state.selectedData))) ? this.state.selectedData : this.props.TimeoffData}
                YearVal = {(_R.not(_R.isEmpty(this.state.selectedData))) ? this.state.year : this.props.TimeoffData.selected_year}
                HandleYearFilterChange={this.HandleYearFilterChange}
                StaffID={this.props.StaffID}
                UserID={this.props.UserID}
                currentYear = {this.state.year}
                GetTimeoffDetails = {this.getTimeoffDetails.bind(this)}/>
            :
            <ErrorPage/>
        )
    }
}

const TimeoffComponent = withStyles(styles)(props => {
    const { classes, TimeoffData:TimeoffDetails, HandleYearFilterChange, YearVal, StaffID, UserID, GetTimeoffDetails, currentYear, staff_events } = props;
    return (
        <Grid container justify="space-between" alignItems="stretch" spacing={2}>
            <Grid item xs={12} sm={12}>
                <form className="form-fullwidth">
                    <Grid item xs={4} md={2}>
                        <FormControl variant="standard">
                            <InputLabel htmlFor="age-simple">Year</InputLabel>
                            <Select
                                value = {YearVal}
                                onChange={HandleYearFilterChange}
                                inputProps={{
                                    name: 'year',
                                    id: 'filter_year',
                                }}
                            >
                            {typeof(TimeoffDetails.years) !== undefined && TimeoffDetails.years.map((year, i) =>
                                <MenuItem value={year} key={'year-'+i}>{year}</MenuItem>
                            )}
                            </Select>
                        </FormControl>
                    </Grid>
                </form>
            </Grid>
            <Grid  item xs={12}>
                <Paper className={classes.paper}>
                    <AdjustmentDetails PTOInfo={_R.type(TimeoffDetails) !== undefined && 'timeoff_type_adjustments' in TimeoffDetails ? TimeoffDetails.timeoff_type_adjustments : undefined}
                            YearVal={YearVal}
                            TimeofftypeDetails = {TimeoffDetails.timeoff_types}
                            StaffID={StaffID}
                            UserID={UserID}
                            Years = {_R.type(TimeoffDetails) !== undefined && 'years' in TimeoffDetails&&TimeoffDetails.years.length?TimeoffDetails.years:undefined }
                            Edit = {_R.type(TimeoffDetails) !== undefined ? TimeoffDetails.edit_permission : undefined}
                            GetTimeoffDetails = {GetTimeoffDetails}
                            CurrentYear={currentYear}
                    />
                </Paper>
            </Grid>
            <Grid  item xs={12}>
                <Paper className={classes.paper}>
                    <TimeofftypePolicyDetails PTOPolicy={_R.type(TimeoffDetails)!== undefined && 'timeoff_type_user_policies' in TimeoffDetails ? TimeoffDetails.timeoff_type_user_policies : undefined}
                            policyEnabledTimeofftypes = {TimeoffDetails.policyenabled_timeofftypes}
                            YearVal={YearVal}
                            StaffID={StaffID}
                            UserID={UserID}
                            Edit = {_R.type(TimeoffDetails) !== undefined ? TimeoffDetails.edit_permission : undefined}
                            eligibilityCriteriaGroups = {TimeoffDetails.criteria_groups}
                    />
                </Paper>
            </Grid>
            <Grid  item xs={12}>
                <Paper className={classes.paper}>
                    <TimeoffConfigOverrideDetails TimeoffPolicy={_R.type(TimeoffDetails) !== undefined && 'timeoff_policy' in TimeoffDetails ? TimeoffDetails.timeoff_policy : undefined}
                            StaffID={StaffID}
                            UserID={UserID}
                            Edit = {_R.type(TimeoffDetails) !== undefined ? TimeoffDetails.edit_permission : undefined}
                            Delete = {_R.type(TimeoffDetails) !== undefined ? TimeoffDetails.delete_permission : undefined}
                    />
                </Paper>
            </Grid>
	     <Grid  item xs={12}>
                <Paper className={classes.paper}>
	    	 {YearVal < TimeoffDetails.cutoff_year_const?
                        <Alert severity="info"> There are no events for the selected time off type. </Alert>
                            :
                            <>
                    <StaffEventsConfigContainer StaffEvents = {_R.type(TimeoffDetails) !== undefined && 'staff_events' in TimeoffDetails ? TimeoffDetails.staff_events : undefined }
			StaffID={StaffID}
                        UserID={UserID}
	    		Edit = {_R.type(TimeoffDetails) !== undefined ? TimeoffDetails.edit_permission : undefined}
                        Delete = {_R.type(TimeoffDetails) !== undefined ? TimeoffDetails.delete_permission : undefined}
                        YearVal = {YearVal}
	    	    />
			</>}
                </Paper>
            </Grid>
        </Grid>
    )
});
