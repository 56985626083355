import React from 'react';
import {StaffEventsConfigComponent} from './StaffEventsConfig.Component';
import {httpServices}  from '../../../../services/httpServices';
import * as _R from 'ramda';  // used 0.04kb

const formFields = {
    'timeoff_type_id' : '',
    'from_date' : null,
    'to_date' : null,
    'eligible_days' : null,
    'default_eligibility': '',
}

export class StaffEventsConfigContainer extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            formFields: formFields,
            errors: {},
            formInputs: {},
            variant : 'success',
            snackbar : false,
	    staff_events :[],
	    event_based_timeoffs : [],
	    open : false,
	    isEdit : false,
	    buttonLoader : false,
        }
	this.onAdd = this.onAdd.bind(this)
	this.onClose = this.onClose.bind(this)
	this.handleOnChange = this.handleOnChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
	this.saveStaffEvent = this.saveStaffEvent.bind(this);
	this.onUpdate = this.onUpdate.bind(this);
        this.onDelete = this.onDelete.bind(this);
	this.snackbarClose = this.snackbarClose.bind(this);
    }
	
    onAdd = (timeoff_id = null) => {
        return httpServices.post('api/timeoff/getStaffEvents', {timeoff_id: timeoff_id})
            .then(response => {
                if (response.data) {
                    let finputs = this.state.formInputs;
		    let ffields = this.state.formFields;
                    finputs['event_based_timeoffs'] = response.data.event_based_timeoffs;
		    ffields['default_eligibility'] = response.data.event_defult_eligibility;
		    ffields['eligible_days'] = response.data.event_defult_eligibility;
                    this.setState({
                        formInputs: finputs,
			formFields: ffields,
                        open: true,
                        id: 0,
                        isEdit: false,
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    onClose = () =>{
        this.setState({
            open: false,
            id: 0,
            errors:{}
        });
	this.clearForm();
    }
     handleOnChange = (evt, value) => {
        const f = this.state.formFields;
        const name = evt.target.name;
        const val = evt.target.value;
        f[name] = evt.target.value;
	if (name == 'timeoff_type_id') {
	    this.onAdd(evt.target.value);
	}
        this.setState({
            formFields: f
        });
        if (value) {
            this.setState({errors: {}});
        }
    };
    handleDateChange = (value, name) => {
        const f = this.state.formFields;
        f[name] = value;
        this.setState({
            formFields: f
        })
    };

    saveStaffEvent =() =>{
    	if (this.handleValidation()) { 
            this.setState({buttonLoader : true});
	    const data = this.state.formFields;
	    data.staff_id = this.props.StaffID;
            data.user_id = this.props.UserID;
            const currentYear = new Date().getFullYear();
            data.filter_year = this.props.YearVal && !isNaN(this.props.YearVal) ? this.props.YearVal : currentYear;
            return httpServices.post('api/timeoff/createStaffEvents', data)
                .then(response => {
                    if (!response.data.errors) {
                        if (response.data.valid) {
                            this.setState({
                                open:false,
                                timeoff_policy: response.data.timeoff_user_configs,
                                snackbar : true,
                                variant : 'success',
                                message : response.data.message,
				staff_events : response.data.staff_events,
				buttonLoader : false,
                            });

                            this.clearForm();
                        }
                    }
                    else {
			this.setState({
                                snackbar : true,
                                variant : 'error',
                                message : response.data.errors,
                                buttonLoader : false,
                            });
                    }
                })
                .catch(function(error) {
                    console.log("Error: "+error);
                });
        }
    } 

     handleValidation() {
        let formIsValid = true;
        let errors = {};        
        if (!this.state.formFields.timeoff_type_id) {
            errors["timeoff_type_id"] = "Cannot be empty";
            formIsValid = false;
        }     
        if (!this.state.formFields.eligible_days) {
            errors["eligible_days"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.from_date) {
            errors["from_date"] = "Cannot be empty";
            formIsValid = false;
        }
        if (!this.state.formFields.to_date) {
            errors["to_date"] = "Cannot be empty";
            formIsValid = false;
        }        
        if(this.state.formFields.from_date && this.state.formFields.to_date){
            let from_date = new Date(this.state.formFields.from_date);
            let to_date = new Date(this.state.formFields.to_date);
            if(from_date > to_date){
                errors["from_date"] = "Please enter valid dates";
                formIsValid = false;
            }
        }
        this.setState({errors: errors});
        return formIsValid;
    }
    onUpdate = (id) => {
        return httpServices.post('api/timeoff/getStaffEvents', {'id': id})
        .then(response => {
               if (response.data) {
		    let finputs = this.state.formInputs;
                    finputs['event_based_timeoffs'] = response.data.event_based_timeoffs;
		    this.setState({
                        formFields: response.data.selected_staff_event,
                        formInputs: finputs,
                        open: true, 
                        id: id,
                        isEdit: true,
                    });
                }      
        })
            .catch(function(error) {
                console.log("Error: "+error);
            });
    }
    onDelete = (id) => {
	const staffID = this.props.StaffID;
        const userID = this.props.UserID;
        const currentYear = new Date().getFullYear();
        const filterYear = this.props.YearVal && !isNaN(this.props.YearVal) ? this.props.YearVal : currentYear;
        return httpServices.post('api/timeoff/deleteStaffEvents', {'id': id, 'staff_id': staffID, 'user_id': userID, 'filter_year': filterYear})
            .then(response => {
                if (response.data) {
                    this.setState({
                        snackbar : true,
                        variant : 'success',
                        message : response.data.message,
		        staff_events : response.data.staff_events
                    });
                }
            })
            .catch(function(error) {
                console.log("Error: "+error);
            });
	
    }
	
    clearForm = () => {
        const ff = this.state.formFields;
        for (let key in ff) {
            ff[key] = null;
        }
        this.setState({
            errors: {},
            formFields: ff
        });
    };
    snackbarClose = () => {
        this.setState({
            snackbar : false,
        });
    }
    render(){
        return(
            <StaffEventsConfigComponent
		  StaffEventsList = {this.state.staff_events.length > 0 ? this.state.staff_events : _R.type(this.props.StaffEvents) !== 'Undefined' ? this.props.StaffEvents: undefined}
            OnAdd = {this.onAdd}
            Close = {this.onClose}
	    Open={this.state.open}
	    FormInputs = {this.state.formInputs}
            HandleOnChange={this.handleOnChange}
	    HandleDateChange = {this.handleDateChange}
	    FormFields = {this.state.formFields}
	    SaveStaffEvent = {this.saveStaffEvent}
	    HandleUpdate = {this.onUpdate}
	    OnDelete = {this.onDelete}
            Errors = {this.state.errors}
	    IsEdit = {this.state.isEdit}
	    Variant = {this.state.variant}
            Message = {this.state.message}
            Snackbar = {this.state.snackbar}
            SnackbarClose = {this.snackbarClose}
	     />
        )
    }
}
