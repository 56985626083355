import React from 'react';
import { httpServices } from '../../../services/httpServices';
import { TimeOffAddComponent } from './TimeOffAdd.Component';
import { checkIfDateIsValid } from '../../common/common';

const hoursPerDay = [
    { label: 'Full Day', value: 'full', disabled: '' },
    { label: 'Half Day', value: 'half', disabled: '' },
    { label: 'Hours', value: 'hour', disabled: '' },
];
let dayHours = 0;
let holidays = [];
let usedCoffDates = [];
let selectedCoffDates = [];
export class AddTimeOff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stateData: {},
            open: false,
            grantOpen: false,
            timeOffEnableHours: '',
            formInputs: {},
            timeoffDetails: {},
            formValues: {},
            duration: '',
            splitInfo: '',
            displaySuggestionFlag: false,
            suggestionError: '',
            postErrors: [],
            validationErrors: {},
            showCoff: false,
            file: {},
            coffMaxDate: null,
            coffMinDate: null,
            availableCoffDates: [],
            buttonLoader : false,
            hoursPerDay : hoursPerDay,
            isEdit: true,
            allowPartialHours: true,
            suggestionChanged: false,
            previousSuggestions: [],
            currentSuggestion: [],
            suggestionChecked: true,
            maxUploadSize : null,
            dailyMinHours : 0,
	    eventBased : 0,
	    eventBasedDetails : [],
	    eventToType : null,
        }
        this.onClode = this.onClose.bind(this);
        this.submitTimeOff = this.submitTimeOff.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.showDuration = this.showDuration.bind(this);
        this.durationChange = this.durationChange.bind(this);
        this.handleSystemSuggestionChange = this.handleSystemSuggestionChange.bind(this);
        this.displaySuggestion = this.displaySuggestion.bind(this);
        this.handleRecipientChange = this.handleRecipientChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleCompDateChange = this.handleCompDateChange.bind(this);
    };
    componentDidMount = () => {
        if (this.props.StartDate) {
            let formValues = this.state.formValues;
            formValues['startDate'] = this.props.StartDate;
            let sDateArr = this.props.StartDate.split('-');
            formValues['startDateObj'] = new Date(sDateArr[0],sDateArr[1]-1,sDateArr[2]);
            if (!formValues['endDate']) {
                formValues['endDate'] = this.props.StartDate;
                formValues['endDateObj'] = new Date(sDateArr[0],sDateArr[1]-1,sDateArr[2]);
            }
            this.setState({
                formValues: formValues,
            });
        }
        let editId = this.props.EditId;
        if (editId && editId > 0) {
            this.getRequestDetails(editId);
        } else {
            this.getTimeOffFormInputs();
        }
    };
    componentDidUpdate(prevProps) {
        if (this.props.Open !== this.state.open && this.props.GrantOpen !== this.state.grantOpen) {
            this.setState({ 
                grantOpen: this.props.GrantOpen, 
                open: this.props.Open
            });
        }
        else if (this.props.Open !== this.state.open) {
            this.setState({ 
                open: this.props.Open
            });
        }
        else if (this.props.GrantOpen !== this.state.grantOpen) {
            this.setState({ 
                grantOpen: this.props.GrantOpen
            });
        }
    }
    onClose = () => {   
        this.props.UpdateParentOnAddSuccess(false);
    }
    submitTimeOff = () => {
        this.setState({ buttonLoader : true});
        let systemSuggestion = false;
        let taskId = 0;
        if (this.state.formValues.systemSuggestion) {
            systemSuggestion = true;
        } else {
            taskId = this.state.formValues.timeoffTask ? this.state.formValues.timeoffTask : 0;
        } 
        let data = {
            system_suggestion: systemSuggestion,
            start_date: this.state.formValues.startDate ? this.state.formValues.startDate : '',
            end_date: this.state.formValues.endDate ? this.state.formValues.endDate : '',
            task: taskId,
            reason: this.state.formValues.reason ? this.state.formValues.reason : '',
            hours: dayHours,
            reference_no: this.state.formValues.refNo,
            attachment: this.state.file,
            remove_file: this.state.formValues.remove_file,
            attachment_id: this.state.formValues.attachment_id,
            hoursPerDay: this.state.formValues.hoursPerDay,
        }
        if (this.props.GrantOpen) {
            data['user_id'] = this.state.formValues.employeeId;
            data['action'] = 'grant'; 
        }
        if (this.state.formValues.custom_recipient) {
            let c_recipients = this.state.formValues.custom_recipient;
            data['custom_users'] = JSON.stringify(c_recipients);
        }
        /*if (selectedCoffDates && selectedCoffDates.length) {
            data['compensate_off'] = JSON.stringify(selectedCoffDates);
        }*/
        if (this.state.formValues.coff_dates && this.state.formValues.coff_dates.length && this.state.showCoff) {
            data['compensate_off'] = JSON.stringify(this.state.formValues.coff_dates);
        } else {
            data['compensate_off'] = JSON.stringify([]);
        }
        if (this.state.formInputs.recipients) {
            let stateDefaultRecipients = this.state.formInputs.recipients;
            let defaultRecipients = [];
            Object.keys(stateDefaultRecipients).map(function (key, p_index) {
                let obj = stateDefaultRecipients[key];
                if (obj.user_id) {
                    defaultRecipients.push(obj.user_id);
                }
                return true;
            });
            if (defaultRecipients.length) {
                data['recipients'] = JSON.stringify(defaultRecipients);
            }
        }
        let valid = this.validateTimeOffDetails(data);
        if (valid) {
            let apiEndpoint = '';
            if (this.props.GrantOpen) { 
                apiEndpoint = 'api/request/timeoff/grant';
                data['action'] = 'grant';
            }
            else {
                if (this.props.EditId) {
                    apiEndpoint = 'api/request/timeoff/edit';
                    data['id'] = this.props.EditId;
                    data['action'] = 'update';
                } else {
                    apiEndpoint = 'api/request/timeoff/create';
                }
            }
            return httpServices.post(apiEndpoint, data)
                .then(response => {
                    if (response.data.permission) {
                        let postErrors = this.state.postErrors;
                        if (response.data.valid) {
                            if (response.data.errors) {
                                this.setState({
                                    postErrors: response.data.errors,
                                    buttonLoader : false,
                                });
                            }
                            else {
                                let updateType = (this.props.GrantOpen) ? 'grant' : 'create';
                                let attachmentFailMsg = '';
                                this.props.UpdateParentOnAddSuccess(true, attachmentFailMsg, '',updateType);
                                if (this.props.DisplayRequestSuccess) {
                                    if (this.props.GrantOpen) {
                                        this.props.DisplayRequestSuccess(true, "Time off granted successfully");
                                    }
                                    else {
                                        this.props.DisplayRequestSuccess(true, "Request submitted successfully");
                                    }
                                }
                                this.setState({ buttonLoader : false});
                            }
                        } else { 
                            let errors = {}; 
                            errors['hours'] = (response.data.error_msgs && response.data.error_msgs['hours']) ? response.data.error_msgs['hours'] : '';
                            errors['hoursPerDay'] = (response.data.error_msgs && response.data.error_msgs['hoursPerDay']) ? response.data.error_msgs['hoursPerDay'] : '';                            
                            errors['coff_date'] = (response.data.error_msgs && response.data.error_msgs['compensate_off']) ? response.data.error_msgs['compensate_off'] : '';
                            errors['sdate'] = (response.data.error_msgs && response.data.error_msgs['start_date']) ? response.data.error_msgs['start_date'] : '';
                            errors['edate'] = (response.data.error_msgs && response.data.error_msgs['end_date']) ? response.data.error_msgs['end_date'] : '';
                            errors['reason'] = (response.data.error_msgs && response.data.error_msgs['reason']) ? response.data.error_msgs['reason'] : '';
                            errors['task'] = (response.data.error_msgs && response.data.error_msgs['task']) ? response.data.error_msgs['task'] : '';
                            errors['user_id'] = (response.data.error_msgs && response.data.error_msgs['user_id']) ? response.data.error_msgs['user_id'] : '';
                            errors['refNo'] = (response.data.error_msgs && response.data.error_msgs['refNo']) ? response.data.error_msgs['refNo'] : '';
                            this.setState({
                                validationErrors : errors,
                                postErrors: response.data.errors,
                                buttonLoader : false,
                            });
                        }
                    } else {
                        this.setState({
                            hasPermission: false,
                            buttonLoader : false,
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        } else {
            this.setState({
                buttonLoader: false,
            });
        }
    };
    getTimeOffFormInputs = () => { 
        let apiEndpoint = '';
        if (this.props.GrantOpen) { 
             apiEndpoint = 'api/request/timeoff/grant';
        }
        else {
             apiEndpoint = 'api/request/timeoff/create';
        }
        
        return httpServices.get(apiEndpoint)
            .then(response => {
                this.props.CloseLoader();
                if (response.data.permission) {
                    let formValues = this.state.formValues; 
                    if (response.data.dailyminhours) {
                        dayHours = response.data.dailyminhours;
                        formValues['hoursPerDay'] = 'full';
                    }
                    formValues['remove_file'] = true; // Default                    
                    this.setState({
                        formInputs: response.data,
                        formValues: formValues,
                        maxUploadSize: response.data.max_size,
                        dailyMinHours: response.data.dailyminhours
                    });                                     
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };
    getGrantTimeOffTasks= (user_id) => { 
        let apiEndpoint = '';
        apiEndpoint = 'api/request/timeoff/getTimeoffTasks';
        let formValues = this.state.formValues;
        
        return httpServices.post(apiEndpoint,{user_id : user_id, pop_type : 'grant', start_date : formValues.startDate, end_date : formValues.endDate})
            .then(response => {
                this.props.CloseLoader();                  
                let formInputs = this.state.formInputs;
                let timeoffTasks =  response.data.timeoff_tasks;
                if(!timeoffTasks.length){
                    formValues['startDate'] = '';
                    formValues['endDate'] = '';
                }
                formValues['timeoffTask'] = timeoffTasks.filter(t => t.task_id == formValues['timeoffTask']).length ? formValues['timeoffTask'] : 0 ;
                formInputs['timeoff_tasks'] = timeoffTasks;
                this.setState({
                    formInputs: formInputs,
                    formValues: formValues,
                    timeoffDetails: {}
                },()=>{
                    if (formValues['timeoffTask']) {
                        this.showPTOdetails(formValues['timeoffTask']);
                    }
                });
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };
    getRequestDetails = (id) => {
        let apiEndpoint = 'api/request/timeoff/edit';
        return httpServices.post(apiEndpoint, { id: id, action: 'get' })
            .then(response => {
                if (response.data.valid) {
                    let formValues = {};
                    let tempFormValues = response.data.request_details;
                    let removeFile = tempFormValues.name ? false : true;
                    if (response.data.request_details) {
                        formValues = {
                            startDate: tempFormValues.start_date,
                            endDate: tempFormValues.end_date,
                            reason: tempFormValues.description,
                            timeoffTask: tempFormValues.task_id,
                            file_name: tempFormValues.name ? tempFormValues.name : null,
                            remove_file: removeFile,
                            attachment_id: tempFormValues.attachment_id,
                            refNo: tempFormValues.reference_no,
                        };
                        if (tempFormValues.start_date) {
                            let sDateArr = tempFormValues.start_date.split('-');
                            formValues['startDateObj'] = new Date(sDateArr[0],sDateArr[1]-1,sDateArr[2]);
                        }
                        if (tempFormValues.end_date) {
                            let eDateArr = tempFormValues.end_date.split('-');
                            formValues['endDateObj'] = new Date(eDateArr[0],eDateArr[1]-1,eDateArr[2]);
                        }
                    }
                    if (response.data.custom_users) {
                        formValues['custom_recipient'] = response.data.custom_users;
                    }
                    let dailyminhours = response.data.dailyminhours;
                    if (dailyminhours) {
                        dayHours = dailyminhours;
                        formValues['hoursPerDay'] = 'full';
                        let hours = response.data.request_details.hours ? response.data.request_details.hours : 0;
                        if (hours) {
                            if (parseFloat(hours)) {
                                dayHours = parseFloat(hours);
                                if (hours == parseFloat(dailyminhours)) {
                                    formValues['hoursPerDay'] = 'full';
                                } else if (hours == (dailyminhours / 2)) {
                                    formValues['hoursPerDay'] = 'half';
                                } else {
                                    formValues['hoursPerDay'] = 'hour';
                                    formValues['hours'] = hours;
                                }
                            }
                        }
                    }
                    let showCoff = false;
                    let availableCoffDates = [];
                    if (tempFormValues.custom_workflow === 'C-OFF') {
                        showCoff = true;
                        if (tempFormValues.compensate_off) {
                            formValues['coff_dates'] = tempFormValues.compensate_off_inputs;
                            availableCoffDates = tempFormValues.compensate_off_inputs;
                        }
                    }
                    this.setState({
                        formInputs: response.data,
                        formValues: formValues,
                        showCoff: showCoff,
                        availableCoffDates: availableCoffDates,
                        isEdit: true,
                        maxUploadSize: response.data.max_size,
                        dailyMinHours: response.data.dailyminhours
                    }, function () {
                        this.showDuration();
                        if (tempFormValues.task_id) {
                            this.showPTOdetails(tempFormValues.task_id);
                        }
                        this.taskChange(formValues.timeoffTask);
                    });
                } else {
                    this.setState({
                        isEdit: false,
                        postErrors: response.data.errors,
                    });
                }
                this.props.CloseLoader();
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    };
    handleDateChange = (value, name) => {
        const form_field = this.state.formValues;
        form_field[name] = value;
        if (name == 'startDate') {
            if (form_field['startDate'] > form_field['endDate']) {
                form_field['endDate'] = form_field['startDate'];
            }
        }
        this.setState({
            formValues: form_field,
	    eventBasedDetails : [],
        }, function() {
            this.getTimeoffTasksOnDate();
            let sdate = checkIfDateIsValid(form_field.startDate) ? form_field.startDate : '';
            let userId = (this.props.GrantOpen && form_field.employeeId) ? form_field.employeeId : this.state.formInputs.user_id;
            let apiEndpoint = 'api/request/timeoff/getUserDailyMinimum';
            let data = {
                start_date: sdate,
                user_id: userId
            }
            return httpServices.post(apiEndpoint, data)
                .then(response => {
                    if (response.data) {
                        if (form_field['hoursPerDay'] !== 'hour') {
                            dayHours = response.data.user_min_hours;
                        }
                        this.setState({
                            dailyMinHours: response.data.user_min_hours,
                        }, () => {
                            this.showDuration();
                            if (!this.props.GrantOpen) {
                                if (!this.props.EditId && this.state.suggestionChecked) {
                                    this.displaySuggestion();
                                }else{
                                    this.getTimeoffReceipients()
                                }
                            }
                            if (this.state.formValues.timeoffTask) {
                                this.showPTOdetails(this.state.formValues.timeoffTask);
                            }
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        })
    };
    handleOnChange = (evt, value) => {
        let errors = {};
        const field = evt.target.name;
        const ff = this.state.formValues;
        if (field === 'timeoffTask') {
            let taskId = evt.target.value;
            this.showPTOdetails(taskId);
            this.showDuration(taskId);
            this.taskChange(taskId);
            this.getTimeoffReceipients(taskId);
        }
        if (field === 'hoursPerDay') {
            this.durationChange(evt.target.value);
        }
        if (field === 'hours') {
            dayHours = evt.target.value;
            let regex = new RegExp("^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$");
            if(regex.test(dayHours)) {
                let hourValue = dayHours.split(':');
                if (hourValue < this.state.dailyMinHours)  {
                    let minute = hourValue[1]/60;
                    dayHours = parseFloat(hourValue[0])+parseFloat(minute.toFixed(2));
                    this.durationChange('', false);
                    errors['hours'] = '';
                }
                else {
                    errors['hours'] = 'Invalid hours';
                    this.durationChange('', true); // hide auto suggestion
                }
            }
            else if (dayHours > 0 &&  isNaN(dayHours) === false && parseFloat(dayHours) <= parseFloat(this.state.dailyMinHours)) {
                    this.durationChange('', false); 
                    errors['hours'] = '';
                }
            else {
                errors['hours'] = 'Invalid hours';
                this.durationChange('', true); // hide auto suggestion
            }
            this.setState({validationErrors: errors});

        }
        if (this.props.GrantOpen && field == 'employeeId') { 
            this.getGrantTimeOffTasks(evt.target.value);
        }
        if (field === 'remove_file') { // Check box
            ff[field] = evt.target.checked;
        } else {
            ff[field] = evt.target.value;
        }
        if (field === "file") {
            let file = evt.target.files[0];
            if (typeof file !== 'undefined') {
                if (file.size > (this.state.maxUploadSize *1024*1024)) {
                    this.setState({ file : file,
                        postErrors: ["File size exceeded. Maximum allowed size is "+this.state.maxUploadSize+" MB."] });
                }
                else {
                    this.setState({ file : file,
                        postErrors : [] });
                }
            }
            else {
                this.setState({ file : file,
                    postErrors : [] });
            }
        } else {
            this.setState({
                formValues: ff,
                postErrors: []
            }, function () {
                if (field === 'startDate' || field === 'endDate') {
                    this.showDuration();
                    if (!this.props.EditId) {
                        this.displaySuggestion();
                    }
                    if (this.state.formValues.timeoffTask) {
                        this.showPTOdetails(this.state.formValues.timeoffTask);
                    }
                }
            });
        }
    };
    showPTOdetails = (taskId) => {
        let sdate = this.state.formValues.startDate ? this.state.formValues.startDate : '';
	let edate = this.state.formValues.endDate ? this.state.formValues.endDate : null;
        let userId = (this.props.GrantOpen && this.state.formValues.employeeId) ? this.state.formValues.employeeId :this.state.user_id;
        if (taskId !== '0' || sdate !== '') {
            let apiEndpoint = 'api/request/timeoff/timeoffinfo';
            return httpServices.post(apiEndpoint, { id: taskId, sdate: sdate, user_id: userId, edate: edate })
                .then(response => {
                    if (response.data) {
                        let timeoffDetails = response.data.timeoff_details ? response.data.timeoff_details : {};
			this.setState({
                            timeoffDetails: timeoffDetails,
			    eventBased : response.data.event_based,
			    eventBasedDetails : this.state.timeoffTask != 0 ? response.data.timeoff_events : [],
			    eventToType : response.data.eventToType,
                       });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }
    showDuration = (taskId = false, hideSuggestion = false) => {
        let sdate = this.state.formValues.startDate;
        let edate = this.state.formValues.endDate;
        let userId = (this.props.GrantOpen && this.state.formValues.employeeId) ? this.state.formValues.employeeId :this.state.formInputs.user_id;
        let task = taskId ? taskId : this.state.formValues.timeoffTask;
        // Setting hours to zero for resetting duration display in case invalid hours.
        let hours = hideSuggestion ? 0 : dayHours;
        if (checkIfDateIsValid(sdate) && checkIfDateIsValid(edate)) {
            let apiEndpoint = 'api/request/timeoff/getduration';
            let data = {
                start_date: sdate,
                end_date: edate,
                hours: hours,
                user_id: userId,
                task_id: task
            }
            return httpServices.post(apiEndpoint, data)
                .then(response => {
                    if (response.data) {
                        this.setState({ duration: response.data.duration });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }
    durationChange = (stateDuration = '',hideSuggestion = false) => {
        let duration = stateDuration ? stateDuration : this.state.formValues.hoursPerDay;
        let dailyminHours = this.state.dailyMinHours;
        let resetSystemSuggestion = '';
        if (this.state.suggestionError !== '') {
            resetSystemSuggestion = false;
        }
        else {
            resetSystemSuggestion = true;
        }
        if (stateDuration !== '' && stateDuration === 'hour') {
            resetSystemSuggestion = false;
            let formValues = this.state.formValues;
            formValues['hours'] = 0;
            this.setState({
                splitInfo: '',
                duration: '',
                formValues: formValues,
                suggestionChanged: false,
            });
            dayHours = 0;
        }
        if (duration === 'half') {
            dayHours = (dailyminHours / 2);
        }
        else if (duration === 'full') {
            dayHours = dailyminHours;
        }
        if (resetSystemSuggestion || (!resetSystemSuggestion && this.state.suggestionError !== '')) {
           this.showDuration(false, hideSuggestion);
        }
        this.taskChange();
        if (!this.props.EditId && !this.props.GrantOpen && resetSystemSuggestion && this.state.suggestionChecked) {
           this.displaySuggestion(hideSuggestion);
        }
    }
    handleSystemSuggestionChange = (event) => {
        let formValues = this.state.formValues;
        formValues['timeoffTask'] = 0;
        formValues['systemSuggestion'] = event.target.checked;
        let suggestionChecked  = event.target.checked;
        if (event.target.checked) { 
            this.displaySuggestion();
        }
        this.setState({
            formValues: formValues,
            suggestionChanged: false,
            splitInfo: '',
            suggestionChecked : suggestionChecked,
            timeoffDetails: {}
        });
        if (!event.target.checked) {
        this.getTimeoffReceipients();
        }
    };
    displaySuggestion = (hideSuggestion = false) => {
        if (checkIfDateIsValid(this.state.formValues.startDate) && checkIfDateIsValid(this.state.formValues.endDate)) {
            let timeoffConfig = this.state.formInputs.timeoff_config;
            if (timeoffConfig === "yes") {
                let sdate = this.state.formValues.startDate;
                let edate = this.state.formValues.endDate;
                // Setting hours to zero for resetting suggestion display in case invalid hours.
                let hours = hideSuggestion ? 0 : dayHours;
                let userId = (this.props.GrantOpen && this.state.formValues.employeeId) ? this.state.formValues.employeeId :this.state.formInputs.user_id;
                let action = (this.props.GrantOpen) ? 'grant' : 'create';
                let apiEndpoint = 'api/request/timeoff/getbalancetimeofftaskshours';
                let data = {
                    start_date: sdate,
                    end_date: edate,
                    hours: hours,
                    user_id: userId,
                    action: action
                }
                return httpServices.post(apiEndpoint, data)
                    .then(response => {
                        if (response.data) {
                            let isValid = response.data.is_valid;
                            if (isValid) {
                                let timeofftypeSplitup = response.data.timeofftype_splitup.timeofftype_info;
                                let splitInfo = '';
                                let taskId = 0;
                                let suggstionSplitup = [];
                                if (timeofftypeSplitup) {                                    
                                    Object.keys(timeofftypeSplitup).map(function (key, p_index) {
                                        let splitObj = timeofftypeSplitup[key]; 
                                        if (typeof splitObj.task_id !== 'undefined') {
                                            taskId = splitObj.task_id;
                                            suggstionSplitup.push(taskId);
                                        }
                                        if (typeof splitObj.hours !== 'undefined') {
                                            splitInfo += splitObj.to_type + "\n";
                                            if (splitObj.custom_workflow !== '') {
                                                if (typeof splitObj.max_possible_hours !== 'undefined' && splitObj.max_possible_hours != null && splitObj.max_possible_hours !== 'N/A') {
                                                    splitInfo += " Unused "+splitObj.custom_workflow+" : " + splitObj.max_possible_hours + " Hours" + "\n";
                                                }
                                            }
                                            if (typeof splitObj.hours !== '') {
                                                if (splitObj.start_date === splitObj.end_date) {
                                                    splitInfo += " Auto-suggested Duration : " + splitObj.hours + " Hours (" + splitObj.start_date + ")\n";
                                                }
                                                else {
                                                    splitInfo += " Auto-suggested Duration : " + splitObj.hours + " Hours (" + splitObj.start_date + " - " + splitObj.end_date + ")\n";
                                                }
                                            }
                                        }
                                    });
                                }
                                let formValues = this.state.formValues;
                                formValues['systemSuggestion'] = (this.props.GrantOpen) ? false : true;
                                let displaySugest = (this.props.GrantOpen) ? false : true;
                                this.setState({
                                    displaySuggestionFlag: displaySugest,
                                    splitInfo: splitInfo,
                                    suggestionError: '',
                                    formValues: formValues,
                                    currentSuggestion: suggstionSplitup,
                                    previousSuggestions: (this.state.currentSuggestion && this.state.currentSuggestion.length) ? this.state.currentSuggestion : ((this.state.previousSuggestions) ? this.state.previousSuggestions : [])
                                });
                                let prevSuggestion = this.state.previousSuggestions;
                                let currentSuggestion = this.state.currentSuggestion;
                                let difference = prevSuggestion.filter(x => currentSuggestion.indexOf(x) === -1);
                                if (currentSuggestion.length && ((prevSuggestion.length > 0 && prevSuggestion.length != currentSuggestion.length) || difference.length > 0)) {
                                    this.setState({
                                        suggestionChanged: true
                                    });                                    
                                }
                                else {
                                    this.setState({
                                        suggestionChanged: false
                                    });
                                }                                
                            }
                            else if (response.data.error_msg === '') {
                                let formValues = this.state.formValues;
                                formValues['systemSuggestion'] = false;
                                this.setState({
                                    displaySuggestionFlag: false,
                                    formValues: formValues,
                                    suggestionError: ''
                                });
                            }
                            else { 
                                let formValues = this.state.formValues;
                                formValues['systemSuggestion'] = false;
                                let displaySugest = (this.props.GrantOpen) ? false : true;
                                this.setState({
                                    displaySuggestionFlag: displaySugest,
                                    formValues: formValues,
                                    suggestionError: response.data.error_msg,
                                });
                            }
                            this.getTimeoffReceipients();
                        }
                    })
                    .catch(function (error) {
                        console.log("Error: " + error);
                    });
            }
        }
    }
    validateTimeOffDetails = (data) => {
        let errors = {};
        let valid = true;
        if (this.props.GrantOpen) {
            if (data['user_id'] === undefined || data['user_id'] === '') { 
                valid = false;
                errors['user_id'] = 'Cannot be empty';
            }
        }        
        if (data['start_date'] === '') { 
            valid = false;
            errors['sdate'] = 'Cannot be empty';
        }
        if (data['start_date'] === 'NaN-NaN-NaN') { // TODO: Temporary fix for validating NaN values
            valid = false;
        }
        if (data['end_date'] === 'NaN-NaN-NaN') {
            valid = false;
        }
        if (data['end_date'] === '') {
            valid = false;
            errors['edate'] = 'Cannot be empty';
        }
        else {
            if (data['end_date'] < data['start_date']) {
                errors['edate'] = "End date should be greater than start date";
                valid = false;
            }
        }
        if (this.state.formValues.hoursPerDay && this.state.formValues.hoursPerDay === 'hour') {
            if (!data['hours'] || data['hours'] <= 0 ) {
                valid = false;
                errors['hours'] = 'Invalid hours';
            }
            if( data['hours'] > parseFloat(this.state.dailyMinHours)) {
                valid = false;
                errors['hours'] = 'Hours should be less than daily minimum hours';
            }
        }
        if (data['reason'] === '') {
            valid = false;
            errors['reason'] = 'Cannot be empty';
        } 
        if ((!data['system_suggestion'] && data['task'] === 0) || (this.props.GrantOpen && data['task'] === 0)) {  
            valid = false;
            errors['task'] = 'Cannot be empty';
        }
        if (typeof this.state.file !== 'undefined')  {
           if (this.state.file.size > (this.state.maxUploadSize *1024*1024)) {
               valid = false;
               this.setState({postErrors: ["File size exceeded. Maximum allowed size is "+this.state.maxUploadSize+" MB."] });
           }
        }
        let timeoffTasks = this.state.formInputs.timeoff_tasks ? this.state.formInputs.timeoff_tasks : [];
        let taskDetails = [];
        timeoffTasks.forEach(function (item, index) {
            if (item.task_id === data['task'] ) {
                taskDetails = item;
            }
        });
        if (taskDetails['custom_workflow'] === 'C-OFF') {
            if (this.state.availableCoffDates.length === 0) {
                valid = false;
                errors['coff_date'] = 'No compensatory time off date is available';
            }
            else if(typeof this.state.formValues.coff_dates === 'undefined'||(this.state.formValues.coff_dates && this.state.formValues.coff_dates.length === 0)){
                valid = false;
                errors['coff_date'] = 'Cannot be empty';
            }
        }
        this.setState({ validationErrors: errors }); 
        return valid;
    }
    taskChange = (task = 0) => {
        let taskId = task ? task : this.state.formValues.timeoffTask;
        let formInputs = this.state.formInputs;
        let hoursPerDay = this.state.hoursPerDay;
        let timeoffTasks = this.state.formInputs.timeoff_tasks ? this.state.formInputs.timeoff_tasks : [];
        let isCoff = false;
        let calendarDays = false;
        let dailyminHours = this.state.formInputs.dailyminhours;
        let duration = '';
        let formValues = this.state.formValues;
        timeoffTasks.forEach(function (item, index) {
            if ((item.task_id === taskId) && (item.custom_workflow === 'C-OFF')) {
                isCoff = true;
            }
            if (item.task_id === taskId) {
                calendarDays = item.calendar_days;
            }
        });
        if (isCoff) {
            let duration = this.state.formValues.hoursPerDay;
            this.validation_period(taskId);
        }
        this.setState({
            showCoff: isCoff,
        });
    }
    validation_period = (task = 0) => {
        let errors = this.state.validationErrors;
        let taskId = task ? task : this.state.formValues.timeoffTask;
        let timeoffTasks = this.state.formInputs.timeoff_tasks ? this.state.formInputs.timeoff_tasks : [];
        let isCoff = false;
        let sdate = this.state.formValues.startDate;
        let formValues = this.state.formValues;
        timeoffTasks.forEach(function (item, index) {
            if ((item.task_id === taskId) && (item.custom_workflow === 'C-OFF')) {
                isCoff = true;
            }
        });
        if (isCoff) {
            let apiEndpoint = 'api/request/timeoff/coffperiod';
            let data = {
                request_id: this.props.EditId,
                sdate: sdate,
                task_id: taskId
            }
            return httpServices.post(apiEndpoint, data)
                .then(response => {
                    if (response.data) {
                        holidays = response.data.holidays;
                        usedCoffDates = response.data.used_coff_dates;
                        let validSdate = new Date(response.data.valid_sdate);
                        let validEdate = new Date(response.data.valid_edate);
                        let availDates = [];
                        if (response.data.fmd_holiday_dates && response.data.fmd_holiday_dates.length) {
                            let tempHolidays = response.data.fmd_holiday_dates;
                            tempHolidays.forEach(function (item, ind) {
                                let valid = false;
                                if (holidays.indexOf(item.date) !== -1) {
                                    if (usedCoffDates.indexOf(item.date) === -1) {
                                        valid = true;
                                    }
                                }
                                if (valid) {
                                    availDates.push(item);
                                }
                            });
                            let timeoffTasks = this.state.formInputs.timeoff_tasks ? this.state.formInputs.timeoff_tasks : [];
                            let taskDetails = [];
                            timeoffTasks.forEach(function (item, index) {
                                if (item.task_id === taskId ) {
                                    taskDetails = item;
                                }
                            });
                            if (taskDetails['custom_workflow'] === 'C-OFF') {
                                if (availDates.length === 0) {
                                    errors['coff_date'] = 'No compensatory time off date is available';
                                }
                            }
                        }
                        if (formValues !== undefined && formValues['coff_dates'] !== undefined) {
                            formValues['coff_dates'] = formValues['coff_dates'].filter(d => (new Date(d.date) >= validSdate && new Date(d.date) <= validEdate));
                        }
                        this.setState({
                            coffMaxDate: validEdate,
                            coffMinDate: validSdate,
                            availableCoffDates: availDates,
                            validationErrors : errors,
                            formValues : formValues
                        });                        
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
        }
    }
    handleRecipientChange = (evt, value) => {
        let ff = this.state.formValues;
        if (value) {
            if (typeof value === 'object' && value) {
                ff['custom_recipient'] = value;
            }
        }
        this.setState({
            formValues: ff,
        });
    };
    handleCompDateChange = (evt, value) => {
        let ff = this.state.formValues;
        if (value) {
            if (typeof value === 'object' && value) {
                ff['coff_dates'] = value;
                selectedCoffDates.push(value);
            }
        }
        this.setState({
            formValues: ff,
        });
    };

    // TODO : Need to replace the date picker with multi day select calendar
    handleCoffDateChange = (date) => {
        let dateobj = new Date(date);
        let year = dateobj.getFullYear();
        let month = dateobj.getMonth() + 1;
        if (month < 10) {
            month = '0' + month;
        }
        let cdate = dateobj.getDate();
        if (cdate < 10) {
            cdate = '0' + cdate;
        }
        let modifiedDate = year + '-' + month + '-' + cdate;
        let valid = this.validateCoffDate(modifiedDate);
        if (valid) {
            if (selectedCoffDates.indexOf(modifiedDate) === -1) {
                selectedCoffDates.push(modifiedDate);
            }
        }
    }; 
    validateCoffDate = (date) => {
        let valid = false;
        if (holidays.indexOf(date) !== -1) {
            if (usedCoffDates.indexOf(date) === -1) {
                valid = true;
            }
        }
        return valid;
    }
    getTimeoffReceipients = (task = 0) => {
        let formInputs = this.state.formInputs;
        let taskId = task ? task : this.state.formValues.timeoffTask;
        let sdate = this.state.formValues.startDate ? this.state.formValues.startDate : '';
        let userId = (this.props.GrantOpen && this.state.formValues.employeeId) ? this.state.formValues.employeeId :this.state.formInputs.user_id;
        let popType = (this.props.GrantOpen) ? 'grant' : 'create'; 
        let apiEndpoint = 'api/request/timeoff/getTimeoffReceipients';
        return httpServices.post(apiEndpoint, { task_id: taskId, sdate: sdate, pop_type: popType,  user_id : userId})
            .then(response => {
                if (response.data) {
                    formInputs.recipients = response.data.recipients;
                    formInputs.approvers = response.data.approvers;
                
                    this.setState({
                        formInputs: formInputs,
                    });
                }
            })
            .catch(function (error) {
                console.log("Error: " + error);
            });
    }
    //Used to fetch timeoff tasks on changing the date
    getTimeoffTasksOnDate = () => {
        let popType = (this.props.GrantOpen) ? 'grant' : 'create'; 
        let sdate = checkIfDateIsValid(this.state.formValues.startDate) ? this.state.formValues.startDate : '';
	    let edate = checkIfDateIsValid(this.state.formValues.endDate) ? this.state.formValues.endDate : null;
        let userId = (this.props.GrantOpen && this.state.formValues.employeeId) ? this.state.formValues.employeeId :this.state.formInputs.user_id;
        let apiEndpoint = 'api/request/timeoff/getTimeoffTasks';
            let data = {
                start_date: sdate,
                pop_type: popType,
                user_id: userId,
		        end_date: edate
            }
            return httpServices.post(apiEndpoint, data)
                .then(response => {
                    if (response.data) {
                        let formInputs = this.state.formInputs;
                        let formValues = this.state.formValues;
                        let timeoffDetails = this.state.timeoffDetails;
                        let selectedTask = (formValues['timeoffTask'] !== undefined) ? formValues['timeoffTask'] : 0;
                        let taskExistsInAllTasks = response.data.timeoff_tasks.some(task => task.task_id === selectedTask)
                        selectedTask = (!taskExistsInAllTasks && selectedTask) ? 0 : selectedTask;
                        if(this.props.GrantOpen){
                            formValues['timeoffTask'] = response.data.timeoff_tasks.filter(t => t.task_id == formValues['timeoffTask']).length ? formValues['timeoffTask'] : 0 ;
                            if (!formValues['timeoffTask']) {
                                timeoffDetails = {};
                            }
                        }
                        formInputs['timeoff_tasks'] = response.data.timeoff_tasks;
                        if (!selectedTask) {
                            timeoffDetails = {};
                        }
                        formValues['timeoffTask'] = selectedTask;
                        this.setState({
                            formInputs: formInputs,
                            timeoffDetails : timeoffDetails,
                            formValues : formValues
                        }, () => {
                            let formValues = this.state.formValues;
                            if (formValues['timeoffTask']) {
                                let taskId = formValues['timeoffTask'];
                                let timeoffTasks = this.state.formInputs['timeoff_tasks'];
                                timeoffTasks.forEach(function (item, index) {
                                    if ((item.task_id === taskId) && (item.custom_workflow === 'C-OFF')) {
                                       this.validation_period(taskId);
                                    }
                                }, this);
                            }
                        });
                    }
                })
                .catch(function (error) {
                    console.log("Error: " + error);
                });
    }

    render() { 
        return (
            <TimeOffAddComponent
                Open={this.state.open}
                GrantOpen={this.state.grantOpen}
                OnClose={this.onClose}
                HoursPerDay={this.state.hoursPerDay}
                FormInputs={this.state.formInputs}
                FormValues={this.state.formValues}
                HandleOnChange={this.handleOnChange}
                TimeoffDetails ={this.state.timeoffDetails}
                Duration={this.state.duration}
                HandleSystemSuggestionChange={this.handleSystemSuggestionChange}
                SplitInfo={this.state.splitInfo}
                SubmitTimeOff={this.submitTimeOff}
                DisplaySuggestionFlag={this.state.displaySuggestionFlag}
                SuggestionError={this.state.suggestionError}
                ErrorsArr={this.state.postErrors}
                ValidationErrors={this.state.validationErrors}
                ShowCoff={this.state.showCoff}
                HandleRecipientChange={this.handleRecipientChange}
                CoffMaxDate={this.state.coffMaxDate}
                CoffMinDate={this.state.coffMinDate}
                AvailableCoffDates={this.state.availableCoffDates}
                Title={this.props.Title}
                HandleDateChange={this.handleDateChange}
                ButtonLoader={this.state.buttonLoader}
                IsEdit={this.state.isEdit}
                AllowPartialHours={this.state.allowPartialHours}                
                HandleCompDateChange = {this.handleCompDateChange}
                SuggestionChanged = {this.state.suggestionChanged}
		EventBased = {this.state.eventBased}
		EventBasedDetails = {this.state.eventBasedDetails}
		EventToType = { this.state.eventToType}
            />
        )
    }
};
